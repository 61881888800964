
<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full form-col-2 pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >other charges
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >automatic month-to-month renewal</label
          >

          <div class="form-row">
            <div class="text-title">
              lease will automatically renew unless either party gives at least
            </div>
            <TextInputSmall name="leaseRenew" type="number" />
            <div class="text-title">days written notice of termination</div>
          </div>

          <label class="form-section-sub-title">additional charges</label>

          <div class="form-row">
            <div class="text-title">late charges start after the</div>
            <TextInputSmall name="lateCharges" type="number" />
            <div class="text-title">day</div>
          </div>
          <div class="form-row">
            <div class="text-title">initial late charge is:</div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="initialLateChargePercentCheckbox"
              />
            </button>

            <TextInputSmall
              name="initialLateChargePercent"
              type="number"
              class="ml-0"
            />
            <div class="text-title">% of one month's rent</div>

            <div class="ml-8 mr-4 text-title">or</div>

            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="initialLateChargeAmountCheckbox"
              />
            </button>

            <TextInputSmall
              name="initialLateChargeAmount"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <div class="text-title">daily late charge is:</div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="dailyLateChargePercentCheckbox"
              />
            </button>

            <TextInputSmall
              name="dailyLateChargePercent"
              type="number"
              class="ml-0"
            />
            <div class="text-title">% of rent per day</div>

            <div class="ml-8 mr-4 text-title">or</div>

            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="dailyLateChargeAmountCheckbox"
              />
            </button>

            <TextInputSmall
              name="dailyLateChargeAmount"
              type="number"
              class="ml-0"
            />
          </div>
          <div class="form-row">
            <div class="text-title">daily late charges cannot exceed</div>
            <TextInputSmall name="dailyLateChargeCanNotExceed" type="number" />
            <div class="text-title">
              days (maximum of 15 days) for any single month's rent
            </div>
          </div>
          <div class="form-row">
            <TextField
              name="returnedCheckCharge"
              label="returned check charge"
              type="number"
            />
            <TextField
              name="initialPetCharge"
              label="initial pet violation charge"
              type="number"
            />
            <TextField
              name="dailyPetCharge"
              label="daily pet violation charge"
              type="number"
            />
          </div>
          <div class="form-row">
            <TextField
              name="monthlyPestControl"
              label="monthly pest control (if any)"
              type="number"
            />
            <TextField
              name="monthlyTrash"
              label="monthly trash/waste (if any)"
              type="number"
            />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">keys</label>

          <div class="form-row">
            <div class="text-title">number of keys</div>
            <TextInputSmall name="numberOfKeys" type="number" />

            <div class="text-title ml-5">unit</div>
            <TextInputSmall name="unitNumber" />
            <div class="text-title ml-5">mailbox</div>
            <TextInputSmall name="mailbox" />
          </div>
          <div class="form-row">
            <TextField
              name="otherAccessDevices"
              label="other access devices for"
            />
          </div>

          <div class="h-4"></div>

          <label class="form-section-sub-title">insurance</label>

          <div class="form-row">
            <div class="text-title">you are:</div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="requiredToBuyRentersInsurance"
              />
            </button>

            <div class="text-title">
              required to buy renter's or liability insurance
            </div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="notRequiredToBuyRentersInsurance"
              />
            </button>

            <div class="text-title">
              not required to buy renter's or liability insurance
            </div>
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextField from "@/components/form/TextField";
import TextInputSmall from "@/components/form/TextInputSmall";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";
import NotifyMixin from "@/mixins/NotifyMixin";

export default {
  name: "OtherCharges",
  components: {
    Loader,
    ModalFooter,
    TextField,
    TextInputSmall,
    CheckboxInputSmall,
    FinalForm,
  },
  mixins: [ModalNavigation, InitializeFormMixin, NotifyMixin],
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },

      leaseRenew: "",
      lateCharges: "",
      initialLateChargePercentCheckbox: "",
      initialLateChargePercent: "",
      initialLateChargeAmount: "",
      initialLateChargeAmountCheckbox: "",
      dailyLateChargePercentCheckbox: "",
      dailyLateChargePercent: "",
      dailyLateChargeAmount: "",
      dailyLateChargeAmountCheckbox: "",
      dailyLateChargeCanNotExceed: "",
      returnedCheckCharge: "",
      initialPetCharge: "",
      dailyPetCharge: "",
      monthlyPestControl: "",
      monthlyTrash: "",
      numberOfKeys: "",
      unitNumber: "",
      mailbox: "",
      otherAccessDevices: "",
      requiredToBuyRentersInsurance: "",
      notRequiredToBuyRentersInsurance: "",
    };
  },
  computed: {
    initialValues() {
      return {
        leaseRenew: "",
        lateCharges: "",
        initialLateChargePercentCheckbox: "",
        initialLateChargePercent: "",
        initialLateChargeAmount: "",
        initialLateChargeAmountCheckbox: "",
        dailyLateChargePercentCheckbox: "",
        dailyLateChargePercent: "",
        dailyLateChargeAmount: "",
        dailyLateChargeAmountCheckbox: "",
        dailyLateChargeCanNotExceed: "",
        returnedCheckCharge: "",
        initialPetCharge: "",
        dailyPetCharge: "",
        monthlyPestControl: "",
        monthlyTrash: "",
        numberOfKeys: "",
        unitNumber: "",
        mailbox: "",
        otherAccessDevices: "",
        requiredToBuyRentersInsurance: "",
        notRequiredToBuyRentersInsurance: "",
      };
    },
  },
  methods: {
    saveData() {
      if (
        !this.$refs.form.formState.values.initialLateChargeAmountCheckbox &&
        !this.$refs.form.formState.values.initialLateChargePercentCheckbox
      ) {
        this.notifyError(
          "Please select one checkbox from initial late charge."
        );
      } else if (
        !this.$refs.form.formState.values.dailyLateChargeAmountCheckbox &&
        !this.$refs.form.formState.values.dailyLateChargePercentCheckbox
      ) {
        this.notifyError("Please select one checkbox from daily late charge.");
      } else if (
        !this.$refs.form.formState.values.requiredToBuyRentersInsurance &&
        !this.$refs.form.formState.values.notRequiredToBuyRentersInsurance
      ) {
        this.notifyError("Please select one checkbox from insurance.");
      }
    },
  },
};
</script>

<style scoped>
input:checked {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
